<template>
  <div>
    <PageHeader />
    <b-row class="mb-3">
      <b-col>
        <b-button-group>
          <b-button
            :variant="isDirectTransaction?'light':''"
            @click="navigateRoute('/credits/agent-player')"
          >
            {{ $t('credits.credit_transaction') }}
          </b-button>
          <b-button
            :variant="isDirectTransaction?'':'light'"
            @click="navigateRoute('/credits/agent-player/direct')"
          >
            {{ $t('credits.credit_direct_transaction') }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <h6>
            {{ `${$t('fields.result')} (${mockRecords.length})`}}
          </h6>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="mockRecords"
        :fields="fields"
        :busy="credit.isFetchingMasterAgentCredits"
        responsive
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(description)="data">
          <b-badge
            v-if="data.value === 'deposit' || data.value === 'direct-deposit' "
            class="badge-soft-success"
          >
            {{ data.value === 'direct-deposit'? $t('credits.direct_deposit'): $t('credits.deposit')}}
          </b-badge>
          <b-badge
            v-if="data.value === 'withdraw' || data.value === 'direct-withdraw' "
            class="badge-soft-danger"
          >
            {{ data.value === 'direct-withdraw'? $t('credits.direct_withdraw'): $t('credits.withdraw')}}
          </b-badge>
        </template>
        <template #cell(credits)="data">
          <span
            v-if="data.item.description === 'deposit'"
            class="text-success"
          >
            + {{ data.value | currency }}
          </span>
          <span
            v-else
            class="text-danger"
          >
            - {{ data.value | currency }}
          </span>
        </template>
        <template #cell(previousCredits)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(remainCredits)="data">
          {{ data.value | currency }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Credit Transaction',
  },
  data() {
    return {
      fields: [
        '#',
        { key: 'transactionNo', label: `${this.$t('credits.transaction_no')}` },
        {
          key: 'description',
          label: this.$t('fields.description'),
        },
        { key: 'operatedBy', label: this.$t('credits.operated_by') },
        {
          key: 'receiver',
          label: this.$t('credits.receiver'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'previousCredits',
          label: this.$t('credits.previous_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'credits',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'remainCredits',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'issuedDate',
          label: this.$t('fields.issued_date'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'remark',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
      mockRecords: [
        {
          transactionNo: '1390',
          description: 'deposit',
          operatedBy: 'Master',
          receiver: 'Umore123',
          previousCredits: 1300000,
          credits: 1000000,
          remainCredits: 300000,
          issuedDate: '10/10/2021',
          remark: '',
        },
        {
          transactionNo: '1390',
          description: 'withdraw',
          operatedBy: 'Master',
          receiver: 'Umore777',
          previousCredits: 239900000,
          credits: 90000,
          remainCredits: 238990000,
          issuedDate: '10/10/2021',
          remark: '',
        },
        {
          transactionNo: '1390',
          description: 'direct-deposit',
          operatedBy: 'Master',
          receiver: 'Umore123',
          previousCredits: 1300000,
          credits: 1000000,
          remainCredits: 300000,
          issuedDate: '10/10/2021',
          remark: '',
        },
        {
          transactionNo: '1390',
          description: 'direct-withdraw',
          operatedBy: 'Master',
          receiver: 'Umore777',
          previousCredits: 239900000,
          credits: 90000,
          remainCredits: 238990000,
          issuedDate: '10/10/2021',
          remark: '',
        },
      ],
    }
  },
  computed: {
    ...mapState(['credit']),
    ...mapGetters(['']),
    isDirectTransaction() {
      return this.$route.name === 'credits_agent_player_direct' ? true : false
    },
  },

  created() {
    // this.fetchMasterAgentCredits(id)
  },
  methods: {
    ...mapActions(['fetchMasterAgentCredits']),
    navigateRoute(path) {
      if (path !== this.$route.name) {
        this.$router.push(path)
      }
    },
  },
}
</script>